/* eslint-disable no-use-before-define */
import React, {useState, useEffect} from 'react';
import {manageClasses, slugLoftyEqualTo} from '../util/componentsUtil';

function CountdownComponent({
  component,
}) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [timeExpired, setTimeExpired] = useState(false);

  function calculateTimeLeft() {
    const getRef = new Date(component?.startfrom);
    const difference = getRef - new Date();
    // const difference = new Date(component?.startfrom).getTime();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      setTimeExpired(true);
    }

    return timeLeft;
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!timeExpired) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);

      return () => clearInterval(timer);
    }
  }, []);

  return (
    <span
      className={manageClasses(component)}
      id={component.attributes?.id}
    >
      {
        component?.components?.map((index) => {
          if (slugLoftyEqualTo(index, 'countdownSection') && !timeExpired) {
            return (
              <div className={manageClasses(index)} id={index?.attributes?.id}>
                {
                  index?.components?.map((section) => {
                    if (slugLoftyEqualTo(section, 'countdownDay')) {
                      return (
                        <div className={manageClasses(section)} id={section?.attributes?.id}>
                          {`${timeLeft?.days < 10 ? `0 ${timeLeft?.days}` : timeLeft?.days}`}
                        </div>
                      );
                    }
                    if (slugLoftyEqualTo(section, 'countdownHour')) {
                      return (
                        <div className={manageClasses(section)} id={section?.attributes?.id}>
                          {`${timeLeft?.hours < 10 ? `0 ${timeLeft?.hours}` : timeLeft?.hours}`}
                        </div>
                      );
                    }
                    if (slugLoftyEqualTo(section, 'countdownMinute')) {
                      return (
                        <div className={manageClasses(section)} id={section?.attributes?.id}>
                          {`${timeLeft?.minutes < 10 ? `0 ${timeLeft?.minutes}` : timeLeft?.minutes}`}
                        </div>
                      );
                    }
                    if (slugLoftyEqualTo(section, 'countdownSeconds')) {
                      return (
                        <div className={manageClasses(section)} id={section?.attributes?.id}>
                          {`${timeLeft?.seconds < 10 ? `0 ${timeLeft?.seconds}` : timeLeft?.seconds}`}
                        </div>
                      );
                    }
                    if (slugLoftyEqualTo(section, 'countdownLabel')) {
                      const seekTextnode = section?.components?.find((innerdata) => (innerdata.type === 'textnode'));
                      return (
                        <div className={manageClasses(section)} id={section?.attributes?.id}>
                          {seekTextnode?.content}
                        </div>
                      );
                    }
                    return null;
                  })
                }
              </div>
            );
          }
          if (slugLoftyEqualTo(index, 'countdownEndtext') && timeExpired) {
            return (
              <span className={manageClasses(index)} id={index?.attributes?.id}>
                {component?.endText || 'EXPIRED'}
              </span>
            );
          }
          return null;
        })
      }
    </span>
  );
}

export default CountdownComponent;
