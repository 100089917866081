import React from 'react';
import {connect} from 'react-redux';
// import error from '../assets/error404/error.svg';
import errorBackground from '../img/404/bg-error_1.png';
import errorImage from '../img/404/astronauta2_1.png';
import {urlDomain, urlPlatformDomain} from '../config/urls';

function Error404Page({token}) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: '#292151',
      }}
    >
      <div style={{margin: 'auto', width: '80%'}}>
        <div className="text-center">
          <img
            src={errorImage}
            alt="Logo"
            style={{
              width: '55%', margin: 'auto', backgroundImage: `url(${errorBackground})`, backgroundSize: 'cover',
            }}
          />
        </div>
        <div
          className="text-center"
          style={{
            marginTop: '50px', fontFamily: ['Nunito', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','), color: '#9CABC2', fontSize: '24px',
          }}
        >
          <p>El Enlace Que Seguiste Es Probablemente</p>
          <p>Se Ha Roto o Se Ha Quitado De La Pagina.</p>
        </div>
        <div className="text-center">
          <button
            style={{
              background: '#201549',
              marginTop: '80px',
            }}
            type="button"
            className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
            onClick={() => {
              if (token) {
                window.location.href = urlPlatformDomain;
              } else {
                window.location.href = urlDomain;
              }
            }}
          >
            Regresar a Inicio
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(Error404Page);
