/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import React, {useEffect, useState} from 'react';
import toastr from 'toastr';
import {manageClasses, slugLoftyEqualTo} from '../util/componentsUtil';
import DynamicComponent from './DynamicComponent';
import DynamicInfiniteCarousel from './DynamicInfiniteCarousel';

function CarouselDynamic({
  component, dataEndpoints, idGetEndpoint,
  objectReader, nameFiledReader, idPostEndpoint,
  idProject, addTokenApp, addUserApp, params, idPutEndpoint,
  // eslint-disable-next-line no-unused-vars
  idDeleteEndpoint, relationParam, token, carouselData, carouselComponents,
  localState, globalVariables, setLocalState, setGlobalState,
  refurbishEndpoint, variableForEndpoint,
}) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items: 5,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 3,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 3,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };
  const [middleItems, setMiddleItems] = useState([]);
  const [hiddenItemsLocalVars, setHiddenItemsLocalVars] = useState([]);
  const [hiddenGlobalItems, setHiddenGlobalItems] = useState([]);
  const updateMiddleItem = (carouselId, middleItem) => {
    setMiddleItems((prevItems) => {
      // Find the existing entry for this carousel or create a new one
      const updatedItems = prevItems.filter((item) => item.id !== carouselId);
      updatedItems.push({id: carouselId, item: middleItem, available: 'use'});
      return updatedItems;
    });
  };

  function searchBooleanState(id, infoData) {
    const resData = infoData?.find((index) => (index._id === id));
    if (resData?.state === false) {
      return false;
    } else if (resData) {
      return true;
    }
    return 'No Variable';
  }

  function findVariableExists(id, localData, globalData) {
    const localSeeker = localData.some((index) => (index._id === id));
    const globalSeeker = globalData.some((index) => (index._id === id));
    if (localSeeker) {
      return 'isLocal';
    }
    if (globalSeeker) {
      return 'isGlobal';
    }
    return '';
  }

  function addItemToArray(idArray, valueToAdd, localData, globalData, setLocalState, setGlobalState) {
    const findLocation = findVariableExists(idArray, localData, globalData);
    if (findLocation === 'isLocal') {
      const updateToData = [...localData];
      const captureObject = updateToData?.find((obj) => (obj._id === idArray));
      const toAddNewInfo = captureObject?.state || [];
      if (captureObject.type === 'cartArray') {
        if (valueToAdd.length > 1) {
          // valueToAdd.forEach((index) => toAddNewInfo.push(index));
          valueToAdd.forEach((index) => {
            const checkExists = toAddNewInfo.findIndex((item) => item.id === index._id);
            if (checkExists !== -1) {
              toAddNewInfo[checkExists].quantity += 1;
            } else {
              toAddNewInfo.push({cartitem: index, id: index._id, quantity: 1});
            }
          });
        } else {
          // toAddNewInfo.push(valueToAdd);
          const checkExists = toAddNewInfo.findIndex((item) => item.id === valueToAdd._id);
          if (checkExists !== -1) {
            toAddNewInfo[checkExists].quantity += 1;
          } else {
            toAddNewInfo.push({cartitem: valueToAdd, id: valueToAdd._id, quantity: 1});
          }
        }
      } else {
        if (valueToAdd.length > 1) {
          valueToAdd.forEach((index) => toAddNewInfo.push(index));
        } else {
          toAddNewInfo.push(valueToAdd);
        }
      }
      if (captureObject) {
        captureObject.state = toAddNewInfo;
        setLocalState(updateToData);
      }
      toastr.success('Se agrego de manera exitosa');
    } else {
      const updateToData = [...globalData];
      const captureObject = updateToData?.find((obj) => (obj._id === idArray));
      const toAddNewInfo = captureObject?.state || [];
      if (captureObject.typeValue === 'cartArray') {
        if (valueToAdd.length > 1) {
          // valueToAdd.forEach((index) => toAddNewInfo.push(index));
          valueToAdd.forEach((index) => {
            const checkExists = toAddNewInfo.findIndex((item) => item.id === index._id);
            if (checkExists !== -1) {
              toAddNewInfo[checkExists].quantity += 1;
            } else {
              toAddNewInfo.push({cartitem: index, id: index._id, quantity: 1});
            }
          });
        } else {
          // toAddNewInfo.push(valueToAdd);
          const checkExists = toAddNewInfo.findIndex((item) => item.id === valueToAdd._id);
          if (checkExists !== -1) {
            toAddNewInfo[checkExists].quantity += 1;
          } else {
            toAddNewInfo.push({cartitem: valueToAdd, id: valueToAdd._id, quantity: 1});
          }
        }
      } else {
        if (valueToAdd.length > 1) {
          // toAddNewInfo = valueToAdd;
          valueToAdd.forEach((index) => toAddNewInfo.push(index));
        } else {
          toAddNewInfo.push(valueToAdd);
        }
      }
      if (captureObject) {
        captureObject.state = toAddNewInfo;
        setGlobalState(updateToData);
      }
      toastr.success('Se agrego de manera exitosa');
    }
  }
  function handleAdditionOfData() {
    try {
      // Filter before sending
      const filterOnlyExistingData = middleItems?.filter((items) => (items.item));
      const cleanData = [];
      filterOnlyExistingData.forEach((data) => {
        const hasLocalHidden = hiddenItemsLocalVars.find((index) => index.id === data.id);
        const hasGlobalHidden = hiddenGlobalItems.find((index) => index.id === data.id);
        if ((hasLocalHidden && hasLocalHidden.state === true) || (hasGlobalHidden && hasGlobalHidden.state === true)) {
          cleanData.push(data.item);
        }
        if (!hasLocalHidden && !hasGlobalHidden) {
          cleanData.push(data.item);
        }
      });
      // console.log(cleanData);
      if (component?.arrayOptionState && cleanData) {
        addItemToArray(component.arrayOptionState, cleanData, localState, globalVariables, setLocalState, setGlobalState);
      }
    } catch (error) {
      toastr.success('No se pudo agregar los datos');
    }
  }

  if (!objectReader || objectReader.lenght <= 0) {
    return null;
  }

  useEffect(() => {
    const booleanPerSection = [];
    carouselComponents?.forEach((model, keyFactor) => {
      if (slugLoftyEqualTo(model, 'booleanDynamicComponent')) {
        const localStateBoolean = searchBooleanState(model?.variables, localState);
        // booleanPerSection.push()
        if (localStateBoolean !== 'No Variable') {
          booleanPerSection.push({id: keyFactor, state: localStateBoolean});
        }
      }
    });
    setHiddenItemsLocalVars(booleanPerSection);
  }, [localState]);

  useEffect(() => {
    const booleanPerSection = [];
    carouselComponents?.forEach((model, keyFactor) => {
      if (slugLoftyEqualTo(model, 'booleanDynamicComponent')) {
        const globalStateBoolean = searchBooleanState(model?.variables, globalVariables);
        // booleanPerSection.push()
        if (globalStateBoolean !== 'No Variable') {
          booleanPerSection.push({id: keyFactor, state: globalStateBoolean});
        }
      }
    });
    setHiddenGlobalItems(booleanPerSection);
  }, [globalVariables]);

  const buttonAreaContainer = component?.components?.find((seeker) => (seeker.sluglofty === 'buttonSectionAddToVar'));
  return (
    <div className={manageClasses(component)} id={component.attributes?.id}>
      {carouselComponents?.map((model, keyFactor) => {
        if (slugLoftyEqualTo(model, 'booleanDynamicComponent')) {
          if (model?.variables) {
            const localStateBoolean = searchBooleanState(model?.variables, localState);
            const globalStateBoolean = searchBooleanState(model?.variables, globalVariables);
            if (localStateBoolean !== 'No Variable') {
              if (localStateBoolean) {
                const trueDiv = model.components?.find((index) => (index.sluglofty === 'booleanTrueDivDynamic'));
                if (!trueDiv) {
                  return null;
                }
                return (
                  <div className={manageClasses(model)} id={model.attributes?.id}>
                    <div className={manageClasses(trueDiv)} id={trueDiv?.attributes?.id} style={{display: localStateBoolean ? 'block' : 'none'}}>
                      {
                        trueDiv?.components?.filter((carouselItems) => (carouselItems.sluglofty === 'reactCarouselComponent' || carouselItems.sluglofty === 'standardButton'))?.map((carouselSection) => {
                          if (carouselSection.sluglofty === 'standardButton') {
                            return (
                              <DynamicComponent
                                idProject={idProject}
                                component={carouselSection}
                                dataEndpoints={dataEndpoints}
                                objectReader={objectReader}
                                params={params}
                                idDeleteEndpoint={idDeleteEndpoint}
                                idGetEndpoint={idGetEndpoint}
                                idPostEndpoint={idPostEndpoint}
                                idPutEndpoint={idPutEndpoint}
                                nameFiledReader={nameFiledReader}
                                relationParam={relationParam}
                                addTokenApp={addTokenApp}
                                addUserApp={addUserApp}
                                token={token}
                                // STATES
                                globalVariables={globalVariables}
                                setGlobalState={setGlobalState}
                                localState={localState}
                                setLocalState={setLocalState}
                                // Input With Variables
                                // inputStateVariable={inputStateVariable}
                                // For Read Db with dbField data
                                rootReaderData={objectReader}
                                refurbishEndpoint={refurbishEndpoint}
                                variableForEndpoint={variableForEndpoint}
                              />
                            );
                          }
                          const itemComponent = carouselSection?.components?.find((index) => index.sluglofty === 'itemListEndpointDatabase');
                          if (itemComponent) {
                            const itemData = objectReader[keyFactor];
                            return (
                              <DynamicInfiniteCarousel
                                idProject={idProject}
                                component={itemComponent}
                                dataEndpoints={dataEndpoints}
                                objectReader={itemData?.data?.data}
                                params={params}
                                idDeleteEndpoint={idDeleteEndpoint}
                                idGetEndpoint={idGetEndpoint}
                                idPostEndpoint={idPostEndpoint}
                                idPutEndpoint={idPutEndpoint}
                                nameFiledReader={nameFiledReader}
                                relationParam={relationParam}
                                addTokenApp={addTokenApp}
                                addUserApp={addUserApp}
                                token={token}
                                // STATES
                                globalVariables={globalVariables}
                                setGlobalState={setGlobalState}
                                localState={localState}
                                setLocalState={setLocalState}
                                // Input With Variables
                                // inputStateVariable={inputStateVariable}
                                // For Read Db with dbField data
                                rootReaderData={itemData?.data?.data}
                                refurbishEndpoint={refurbishEndpoint}
                                variableForEndpoint={variableForEndpoint}
                                id={keyFactor}
                                responsiveConfig={responsive}
                                items={itemData?.data?.data}
                                updateMiddleItem={updateMiddleItem}
                                originModel={model}
                              />
                            );
                          }
                          return null;
                        })
                      }
                    </div>
                  </div>
                );
              } else {
                const falseDiv = model.components?.find((index) => (index.sluglofty === 'booleanFalseDivDynamic'));
                if (!falseDiv) {
                  return null;
                }
                return (
                  <div className={manageClasses(model)} id={model.attributes?.id}>
                    <div className={manageClasses(falseDiv)} id={falseDiv?.attributes?.id} style={{display: !localStateBoolean ? 'block !important' : 'none'}}>
                      {
                        falseDiv?.components?.filter((carouselItems) => (carouselItems.sluglofty === 'standardButton'))?.map((carouselSection) => {
                          if (carouselSection.sluglofty === 'standardButton') {
                            return (
                              <DynamicComponent
                                idProject={idProject}
                                component={carouselSection}
                                dataEndpoints={dataEndpoints}
                                objectReader={objectReader}
                                params={params}
                                idDeleteEndpoint={idDeleteEndpoint}
                                idGetEndpoint={idGetEndpoint}
                                idPostEndpoint={idPostEndpoint}
                                idPutEndpoint={idPutEndpoint}
                                nameFiledReader={nameFiledReader}
                                relationParam={relationParam}
                                addTokenApp={addTokenApp}
                                addUserApp={addUserApp}
                                token={token}
                                // STATES
                                globalVariables={globalVariables}
                                setGlobalState={setGlobalState}
                                localState={localState}
                                setLocalState={setLocalState}
                                // Input With Variables
                                // inputStateVariable={inputStateVariable}
                                // For Read Db with dbField data
                                rootReaderData={objectReader}
                                refurbishEndpoint={refurbishEndpoint}
                                variableForEndpoint={variableForEndpoint}
                              />
                            );
                          }
                          return null;
                        })
                      }
                    </div>
                  </div>
                );
              }
            } else if (globalStateBoolean !== 'No Variable') {
              if (globalStateBoolean) {
                const trueDiv = model.components?.find((index) => (index.sluglofty === 'booleanTrueDivDynamic'));
                if (!trueDiv) {
                  return null;
                }
                return (
                  <div className={manageClasses(model)} id={model.attributes?.id}>
                    <div className={manageClasses(trueDiv)} id={trueDiv?.attributes?.id} style={{display: globalStateBoolean ? 'block' : 'none'}}>
                      {
                        trueDiv?.components?.filter((carouselItems) => (carouselItems.sluglofty === 'reactCarouselComponent' || carouselItems.sluglofty === 'standardButton'))?.map((carouselSection) => {
                          if (carouselSection.sluglofty === 'standardButton') {
                            return (
                              <DynamicComponent
                                idProject={idProject}
                                component={carouselSection}
                                dataEndpoints={dataEndpoints}
                                objectReader={objectReader}
                                params={params}
                                idDeleteEndpoint={idDeleteEndpoint}
                                idGetEndpoint={idGetEndpoint}
                                idPostEndpoint={idPostEndpoint}
                                idPutEndpoint={idPutEndpoint}
                                nameFiledReader={nameFiledReader}
                                relationParam={relationParam}
                                addTokenApp={addTokenApp}
                                addUserApp={addUserApp}
                                token={token}
                                // STATES
                                globalVariables={globalVariables}
                                setGlobalState={setGlobalState}
                                localState={localState}
                                setLocalState={setLocalState}
                                // Input With Variables
                                // inputStateVariable={inputStateVariable}
                                // For Read Db with dbField data
                                rootReaderData={objectReader}
                                refurbishEndpoint={refurbishEndpoint}
                                variableForEndpoint={variableForEndpoint}
                              />
                            );
                          }
                          const itemComponent = carouselSection?.components?.find((index) => index.sluglofty === 'itemListEndpointDatabase');
                          if (itemComponent) {
                            const itemData = objectReader[keyFactor];
                            return (
                              <DynamicInfiniteCarousel
                                idProject={idProject}
                                component={itemComponent}
                                dataEndpoints={dataEndpoints}
                                objectReader={itemData?.data?.data}
                                params={params}
                                idDeleteEndpoint={idDeleteEndpoint}
                                idGetEndpoint={idGetEndpoint}
                                idPostEndpoint={idPostEndpoint}
                                idPutEndpoint={idPutEndpoint}
                                nameFiledReader={nameFiledReader}
                                relationParam={relationParam}
                                addTokenApp={addTokenApp}
                                addUserApp={addUserApp}
                                token={token}
                                // STATES
                                globalVariables={globalVariables}
                                setGlobalState={setGlobalState}
                                localState={localState}
                                setLocalState={setLocalState}
                                // Input With Variables
                                // inputStateVariable={inputStateVariable}
                                // For Read Db with dbField data
                                rootReaderData={itemData?.data?.data}
                                refurbishEndpoint={refurbishEndpoint}
                                variableForEndpoint={variableForEndpoint}
                                id={keyFactor}
                                responsiveConfig={responsive}
                                items={itemData?.data?.data}
                                updateMiddleItem={updateMiddleItem}
                              />
                            );
                          }
                          return null;
                        })
                      }
                    </div>
                  </div>
                );
              } else {
                const falseDiv = model.components?.find((index) => (index.sluglofty === 'booleanFalseDivDynamic'));
                if (!falseDiv) {
                  return null;
                }
                return (
                  <div className={manageClasses(model)} id={model.attributes?.id}>
                    <div className={manageClasses(falseDiv)} id={falseDiv?.attributes?.id} style={{display: !globalStateBoolean ? 'block !important' : 'none'}}>
                      {
                          falseDiv?.components?.filter((carouselItems) => (carouselItems.sluglofty === 'standardButton'))?.map((carouselSection) => {
                            if (carouselSection.sluglofty === 'standardButton') {
                              return (
                                <DynamicComponent
                                  idProject={idProject}
                                  component={carouselSection}
                                  dataEndpoints={dataEndpoints}
                                  objectReader={objectReader}
                                  params={params}
                                  idDeleteEndpoint={idDeleteEndpoint}
                                  idGetEndpoint={idGetEndpoint}
                                  idPostEndpoint={idPostEndpoint}
                                  idPutEndpoint={idPutEndpoint}
                                  nameFiledReader={nameFiledReader}
                                  relationParam={relationParam}
                                  addTokenApp={addTokenApp}
                                  addUserApp={addUserApp}
                                  token={token}
                                  // STATES
                                  globalVariables={globalVariables}
                                  setGlobalState={setGlobalState}
                                  localState={localState}
                                  setLocalState={setLocalState}
                                  // Input With Variables
                                  // inputStateVariable={inputStateVariable}
                                  // For Read Db with dbField data
                                  rootReaderData={objectReader}
                                  refurbishEndpoint={refurbishEndpoint}
                                  variableForEndpoint={variableForEndpoint}
                                />
                              );
                            }
                            return null;
                          })
                        }
                    </div>
                  </div>
                );
              }
              // updateMiddleItem(keyFactor, undefined);
              // return null;
            }
            return null;
          }
        }
        if (slugLoftyEqualTo(model, 'reactCarouselComponent')) {
          // console.log(model);
          const itemComponent = model?.components?.find((index) => index.sluglofty === 'itemListEndpointDatabase');
          if (!itemComponent) {
            return null;
          }
          if (!objectReader) {
            return null;
          }
          // console.log('hey', itemComponent, objectReader[keyFactor]);
          const itemData = objectReader[keyFactor];
          return (
            <DynamicInfiniteCarousel
              idProject={idProject}
              component={itemComponent}
              dataEndpoints={dataEndpoints}
              objectReader={itemData?.data?.data}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              relationParam={relationParam}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              token={token}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              // Input With Variables
              // inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={itemData?.data?.data}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              id={keyFactor}
              responsiveConfig={responsive}
              items={itemData?.data?.data}
              updateMiddleItem={updateMiddleItem}
              originModel={model}
            />
          );
        }
        return null;
      })}
      {buttonAreaContainer && (
        <div className={manageClasses(buttonAreaContainer)} id={buttonAreaContainer.attributes?.id}>
          {
          buttonAreaContainer?.components?.map((seekButton) => {
            if (slugLoftyEqualTo(seekButton, 'addToVarButton')) {
              return (
                <button
                  type="button"
                  name={seekButton?.attributes?.name}
                  id={seekButton?.attributes?.id}
                  className={manageClasses(seekButton)}
                  onClick={handleAdditionOfData}
                >
                  {
                    seekButton?.components?.map((componentdata) => (
                      <DynamicComponent
                        component={componentdata}
                      />
                    ))
                  }
                </button>
              );
            }
            return null;
          })
        }
        </div>
      )}
      {/* <div>
        <h2>All Middle Items</h2>
        <ul>
          {middleItems.map((middleItem, index) => (
            <li key={index}>
              Carousel {JSON.stringify(middleItem)}
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
}

export default CarouselDynamic;
