import React, {useState} from 'react';
import {manageClasses, slugLoftyEqualTo} from '../util/componentsUtil';
import DynamicComponent from './DynamicComponent';

function DynamicDropdownMenu({
  component, dataEndpoints, idGetEndpoint,
  objectReader, nameFiledReader, idPostEndpoint,
  idProject, addTokenApp, addUserApp, params, idPutEndpoint,
  idDeleteEndpoint, relationParam, token, deletetokenApp,
  dataGenerate, relationParamData,
  // para el plugin del calendario
  changeNextMonth, activeMonth, changeHour,
  changeLastMonth, now, selectDate, listHours,
  submitCalendar, userAuthVariables,
  // individual modal
  // idModalHandler,
  user,
  // para saber si se usa el usuario autenticado para agregar un dato
  useCurrentUserAddDatabase,
  // variable para saber si el formulario tiene multiples archivos
  allowMultipleAttachment,
  // para el plugin de 3 carruseles de productos
  idGetEnpointSwiper1,
  idGetEnpointSwiper2,
  idGetEnpointSwiper3,
  userInfo,
  databaseSelectionForFields,
  // VARIABLE OR STATES
  globalVariables,
  setGlobalState,
  localState,
  setLocalState,
  // Input With Variables
  inputStateVariable,
  // For Read Db with dbField data
  rootReaderData,
  refurbishEndpoint,
  variableForEndpoint,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div
      className={manageClasses(component)}
      id={component.attributes?.id}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {
        component?.components?.map((sections) => {
          if (slugLoftyEqualTo(sections, 'dropdownMenuTrigger')) {
            return (
              <div className={manageClasses(sections)} id={sections?.attributes?.id}>
                {sections?.components?.map((data) => (
                  <DynamicComponent
                    idProject={idProject}
                    component={data}
                    dataEndpoints={dataEndpoints}
                    objectReader={objectReader}
                    params={params}
                    idDeleteEndpoint={idDeleteEndpoint}
                    idGetEndpoint={idGetEndpoint}
                    idPostEndpoint={idPostEndpoint}
                    idPutEndpoint={idPutEndpoint}
                    nameFiledReader={nameFiledReader}
                    relationParam={relationParam}
                    deletetokenApp={deletetokenApp}
                    dataGenerate={dataGenerate}
                    userAuthVariables={userAuthVariables}
                    // plugin del calnedario
                    activeMonth={activeMonth}
                    changeLastMonth={changeLastMonth}
                    changeNextMonth={changeNextMonth}
                    listHours={listHours}
                    now={now}
                    selectDate={selectDate}
                    changeHour={changeHour}
                    submitCalendar={submitCalendar}
                    addTokenApp={addTokenApp}
                    addUserApp={addUserApp}
                    user={user}
                    useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                    token={token}
                    allowMultipleAttachment={allowMultipleAttachment}
                    idGetEnpointSwiper1={idGetEnpointSwiper1}
                    idGetEnpointSwiper2={idGetEnpointSwiper2}
                    idGetEnpointSwiper3={idGetEnpointSwiper3}
                    relationParamData={relationParamData}
                    userInfo={userInfo}
                    databaseSelectionForFields={databaseSelectionForFields}
                    // STATES
                    globalVariables={globalVariables}
                    setGlobalState={setGlobalState}
                    localState={localState}
                    setLocalState={setLocalState}
                    // Input With Variables
                    inputStateVariable={inputStateVariable}
                    // For Read Db with dbField data
                    rootReaderData={rootReaderData}
                    refurbishEndpoint={refurbishEndpoint}
                    variableForEndpoint={variableForEndpoint}
                  />
                ))}
              </div>
            );
          }
          if (slugLoftyEqualTo(sections, 'dropdownMenuContent') && isHovered) {
            return (
              <div className={manageClasses(sections)} id={sections?.attributes?.id} style={{display: isHovered ? 'block' : 'none'}}>
                {sections?.components?.map((data) => (
                  <DynamicComponent
                    idProject={idProject}
                    component={data}
                    dataEndpoints={dataEndpoints}
                    objectReader={objectReader}
                    params={params}
                    idDeleteEndpoint={idDeleteEndpoint}
                    idGetEndpoint={idGetEndpoint}
                    idPostEndpoint={idPostEndpoint}
                    idPutEndpoint={idPutEndpoint}
                    nameFiledReader={nameFiledReader}
                    relationParam={relationParam}
                    deletetokenApp={deletetokenApp}
                    dataGenerate={dataGenerate}
                    userAuthVariables={userAuthVariables}
                    // plugin del calnedario
                    activeMonth={activeMonth}
                    changeLastMonth={changeLastMonth}
                    changeNextMonth={changeNextMonth}
                    listHours={listHours}
                    now={now}
                    selectDate={selectDate}
                    changeHour={changeHour}
                    submitCalendar={submitCalendar}
                    addTokenApp={addTokenApp}
                    addUserApp={addUserApp}
                    user={user}
                    useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                    token={token}
                    allowMultipleAttachment={allowMultipleAttachment}
                    idGetEnpointSwiper1={idGetEnpointSwiper1}
                    idGetEnpointSwiper2={idGetEnpointSwiper2}
                    idGetEnpointSwiper3={idGetEnpointSwiper3}
                    relationParamData={relationParamData}
                    userInfo={userInfo}
                    databaseSelectionForFields={databaseSelectionForFields}
                    // STATES
                    globalVariables={globalVariables}
                    setGlobalState={setGlobalState}
                    localState={localState}
                    setLocalState={setLocalState}
                    // Input With Variables
                    inputStateVariable={inputStateVariable}
                    // For Read Db with dbField data
                    rootReaderData={rootReaderData}
                    refurbishEndpoint={refurbishEndpoint}
                    variableForEndpoint={variableForEndpoint}
                  />
                ))}
              </div>
            );
          }
          return null;
        })
      }
    </div>
  );
}

export default DynamicDropdownMenu;
